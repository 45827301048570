import { FloraButton } from '@grupoboticario/flora-react';

export type ToogleButtonProps = {
  label: string;
  altLabel: string;
  isSelected: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  onSelect: () => void;
};

export const ToogleButton = ({
  label,
  altLabel,
  isSelected,
  isLoading,
  isDisabled = false,
  onSelect,
}: ToogleButtonProps) => {
  return (
    <FloraButton
      hierarchy={'secondary'}
      isLoading={isLoading}
      disabled={isDisabled}
      aria-label={altLabel}
      css={{ backgroundColor: isSelected ? '$nonPrimaryButtonActive' : '' }}
      onClick={onSelect}
    >
      {label}
    </FloraButton>
  );
};
