import { fetcher } from '@/shared/services/api/fetcher';
import { Account, Reseller } from '@/shared/types/interfaces';

type Subscription = {
  accountId: string;
  contactId: string;
  subscribed: boolean;
};

export async function isSubscribed({
  accountId,
  contactId,
}: {
  accountId: Account['id'];
  contactId: Reseller['id'];
}): Promise<boolean> {
  const uri = `v1/accounts/${accountId}/subscriptions/${contactId}/subscribed`;
  const { subscribed } = await fetcher.get<Subscription>(uri);
  return subscribed;
}
