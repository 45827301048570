import { ProductRuptureMain } from './main';
import { ProductRuptureWrapper } from './wrapper';
import { Copilots } from './types';
import { RupturedProductItem } from './ruptured-product-item';

export function RupturedProductList({
  rupturedProducts,
  chatId,
  deleteRecommendation,
  isRefetching,
}: {
  rupturedProducts: Copilots;
  chatId: string;
  deleteRecommendation: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
  isRefetching: boolean;
}) {
  return (
    <ProductRuptureWrapper isRefetching={isRefetching}>
      <ProductRuptureMain css={{ backgroundColor: '$backgroundSecondary' }}>
        {rupturedProducts.map(({ id, ruptura, message, feedback }) => {
          return (
            <RupturedProductItem
              chatId={chatId}
              key={id}
              deleteRecommendation={deleteRecommendation}
              rupture={ruptura}
              ruptureId={id}
              ruptureRecommendedMessage={message}
              ruptureRecommendedMessageFeedback={feedback}
            />
          );
        })}
      </ProductRuptureMain>
    </ProductRuptureWrapper>
  );
}
