import { Product } from './types';
import { RupturedProductRecommendedTitle } from './ruptured-product-recommended-title';
import { RupturedProductRecommendedNotFound } from './ruptured-product-recommended-not-found';
import { ProductDescription } from './product-description';
import { SimilarProductRecommendedMessage } from './similar-product-recommended-message';
import { isAvailableProduct } from './isAvailableProduct';
import { Flex } from '@grupoboticario/flora-react';

export function SimilarProduct({
  similarProduct,
  ruptureRecommendedMessage,
  recommendedMessageSent,
  ruptureRecommendedMessageFeedback,
  sendFeedback,
  chatId,
  copilotId,
  parentCopilotId,
  sendNow,
  recommendedMessageIsPending,
}: {
  similarProduct: Product | null;
  ruptureRecommendedMessage: string;
  recommendedMessageSent: boolean;
  ruptureRecommendedMessageFeedback: number;
  sendFeedback: ({ feedback, chatId, copilotId }: { feedback: number; chatId: string; copilotId: string }) => void;
  chatId: string;
  copilotId: string;
  parentCopilotId: string;
  sendNow: () => void;
  recommendedMessageIsPending: boolean;
}) {
  const similarProductAvailable = isAvailableProduct(similarProduct);

  if (similarProduct === null || !similarProductAvailable) {
    return <RupturedProductRecommendedNotFound />;
  }

  if (similarProductAvailable) {
    return (
      <>
        <RupturedProductRecommendedTitle />
        <Flex direction="row" align="center" wrap="nowrap">
          <ProductDescription product={similarProduct} isSimilar={true} />
        </Flex>
        <SimilarProductRecommendedMessage
          ruptureRecommendedMessage={ruptureRecommendedMessage}
          recommendedMessageSent={recommendedMessageSent}
          ruptureRecommendedMessageFeedback={ruptureRecommendedMessageFeedback}
          sendFeedback={sendFeedback}
          chatId={chatId}
          copilotId={copilotId}
          parentCopilotId={parentCopilotId}
          sendNow={sendNow}
          recommendedMessageIsPending={recommendedMessageIsPending}
        />
      </>
    );
  }
}
