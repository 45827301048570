import { EventEnum } from '../eventEnum';
import { AnalyticsEvent } from '../interfaces/analyticsEvent';
import { ReadonlyEvent } from './readOnlyEvent.type';

export const clickToLikeEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.CLICK_TO_LIKE,
  },
};

export const discardRecommendation: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.DISCARD_RECOMMENDATION,
  },
};

export const backToRecommendation: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.BACK_TO_RECOMMENDATION,
  },
};

export const clickDontLikeEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.CLICK_DONT_LIKE,
  },
};

export const loadingRecommendationsEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.LOADING_RECOMMENDATIONS,
  },
};

export const generateMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.GENERATE_MESSAGE,
  },
};

export const closeRecommendationEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.CLOSE_RECOMMENDATION,
  },
};

export const sendMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.SEND_MESSAGE,
  },
};

export const generateNewMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.GENERATE_NEW_MESSAGE,
  },
};
export const clickTryToCopyMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.TRY_TO_COPY_MESSAGE,
  },
};

export const clickTryToSendMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.TRY_TO_SEND_MESSAGE,
  },
};

export const clickTryToGenerateMessageEvent: ReadonlyEvent<AnalyticsEvent> = {
  event: 'analytics-event',
  event_parameters: {
    event_name: 'interaction_chat_ca',
    cd_interaction_detail: EventEnum.TRY_TO_GENERATE_MESSAGE,
  },
};
