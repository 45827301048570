import { Dropdown, DropdownItem } from '@grupoboticario/flora-react';
import { ChangeEvent, ReactElement } from 'react';

type DropdownSingleSelectorOption = {
  label: string;
  value: string;
  icon?: ReactElement;
};

type DropdownSingleSelectorProps = {
  id: string;
  label: string;
  altLabel: string;
  options: DropdownSingleSelectorOption[];
  selectedValue?: string;
  includeAllOption?: boolean;
  disabled?: boolean;
  onSelect: (value: string) => void;
};

export const DropdownSingleSelector = ({
  id,
  label,
  altLabel,
  options,
  selectedValue = '',
  includeAllOption = true,
  disabled = false,
  onSelect,
}: DropdownSingleSelectorProps) => {
  const handleSelectOption = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSelect(value);
  };

  return (
    <Dropdown
      css={{ width: '100%', height: '$8' }}
      id={id}
      labelText={label}
      aria-label={altLabel}
      onChange={handleSelectOption}
      disabled={disabled || options.length === 0}
      value={selectedValue}
    >
      {includeAllOption && <DropdownItem value="">Todas</DropdownItem>}

      {options.map(option => {
        return (
          <DropdownItem key={option.value} value={option.value}>
            {option.icon} {option.label}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};
