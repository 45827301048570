import { Loading } from '@/shared/components';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';

export function ProductRuptureHeader({ isRefetching }: { isRefetching: boolean }) {
  return (
    <Flex
      direction="row"
      justify="space-between"
      as="header"
      css={{ padding: '$4', boxShadow: 'inset 0 -1px 0 #E5E7E5', flex: '0 1 auto' }}
    >
      <Flex direction="column">
        <FloraTypography as="h1" fontSize="bodyLargeShort" fontWeight="bold" css={{ margin: 0 }}>
          Recomendações
        </FloraTypography>
        <FloraTypography as="h2" fontSize="exceptionsAuxiliar" css={{ margin: '$2 0 0 0' }}>
          Produtos sem estoque
        </FloraTypography>
      </Flex>
      {isRefetching && <Loading />}
    </Flex>
  );
}
