import { backToRecommendation, discardRecommendation } from '@/shared/services/eventTracker/events/events';
import { trackInteractionEvent } from '@/shared/services/eventTracker/trackerService';
import { Flex, FloraButton } from '@grupoboticario/flora-react';
import { TrashIcon, ArrowLeftIcon } from '@grupoboticario/flora-react-icons';

export function ConfirmDeletion({
  deleteCopilot,
  chatId,
  copilotId,
  setConfirmDelete,
}: {
  deleteCopilot: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
  chatId: string;
  copilotId: string;
  setConfirmDelete: (value: boolean) => void;
}) {
  const handleDeleteCopilot = () => {
    deleteCopilot({ chatId, copilotId });
    trackInteractionEvent(discardRecommendation);
  };

  const handleSetConfirmDelete = () => {
    setConfirmDelete(false);
    trackInteractionEvent(backToRecommendation);
  };

  return (
    <Flex direction="column" wrap="wrap" gapY="$2" css={{ marginTop: '$4' }}>
      <FloraButton
        size="small"
        hierarchy="primary"
        styleSemantic="warning"
        has="iconRight"
        icon={<TrashIcon />}
        isFull
        onClick={() => handleDeleteCopilot()}
      >
        Descartar
      </FloraButton>
      <FloraButton
        size="small"
        hierarchy="secondary"
        styleSemantic="neutral"
        has="iconLeft"
        icon={<ArrowLeftIcon />}
        isFull
        onClick={() => handleSetConfirmDelete()}
      >
        Voltar
      </FloraButton>
    </Flex>
  );
}
