import { Flex } from '@grupoboticario/flora-react';
import { TagSelect, UnreadButton, ResultCount, ResetFiltersButton } from './components';

export const FiltersPanel = () => {
  return (
    <Flex direction={'column'} gap={'$2'}>
      <Flex direction={'row'} gap={'$2'}>
        <UnreadButton />
        <TagSelect />
      </Flex>
      <Flex direction={'row'} align={'center'} gap={'$2'} css={{ height: '$8' }}>
        <ResultCount />
        <ResetFiltersButton />
      </Flex>
    </Flex>
  );
};
