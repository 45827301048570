import { BaseEvent } from './interfaces';
import { GtmTracker, IEventTracker } from '@vdi/event-tracker';

const GtmTrackerInstance = GtmTracker as unknown as IEventTracker<BaseEvent, unknown>;

export function trackInteractionEvent<T extends BaseEvent>(event: T) {
  GtmTrackerInstance.track({
    ...event,
  });
}
