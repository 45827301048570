import { FloraTypography } from '@grupoboticario/flora-react';

export function RupturedProductRecommendedTitle() {
  return (
    <FloraTypography
      fontSize="bodySmallShort"
      as="p"
      css={{
        position: 'relative',
        margin: '$4 0',
        color: '$nonInteractiveAuxiliar',
        textAlign: 'center',
        textTransform: 'uppercase',
        '&::before': {
          content: '',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '1px',
          borderTop: '1px solid $nonInteractiveAltAuxiliar',
          top: 'calc(50%)',
          left: 0,
          zIndex: 0,
        },
      }}
    >
      <FloraTypography
        as="span"
        fontSize="exceptionsRestricted"
        css={{ position: 'relative', backgroundColor: 'white', padding: '$0 $4', zIndex: 1 }}
      >
        Recomendações
      </FloraTypography>
    </FloraTypography>
  );
}
