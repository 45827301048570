import { fetcher } from '@/shared/services/api/fetcher';
import { GetChatsResponse } from '@/shared/types/interfaces';

export async function getChats(
  next: string | undefined,
  tags: string | undefined,
  unreadFilter: boolean,
): Promise<GetChatsResponse> {
  let uri = `v1/chats`;
  if (next) {
    uri += `?next=${next}`;
  }

  if (tags) {
    uri += next ? `&tags=${tags}` : `?tags=${tags}`;
  }

  if (unreadFilter) {
    uri += next || tags ? `&status=unread` : `?status=unread`;
  }

  return fetcher.get<GetChatsResponse>(uri, {});
}
