import { useState } from 'react';
import { updateTag } from '@/shared/services/api/instant-messenger/service/updateTagOnList';
import { deleteTagFromList } from '@/shared/services/api/instant-messenger/service/deleteTagFromList';
import { events } from '@/shared/services/events';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { ChatTagType } from '../components';

export function useEditTag(tag: ChatTagType, setTagList: any, setNewTag: any, refetchTags: any) {
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [selectedName, setSelectedName] = useState(tag.name);
  const [selectedColor, setSelectedColor] = useState(tag.color);

  const handleSubmit = () => {
    if (confirmingDelete) {
      handleDeleteTagFromList(tag.id);
    } else {
      handleUpdateTag();
    }
  };

  const handleUpdateTag = async () => {
    try {
      const name = selectedName || tag.name;
      const color = selectedColor || tag.color;
      const updatedTag = { name, color };
      const res = await updateTag(tag.id, updatedTag);
      setTagList(prevTagList => prevTagList.map(t => (t.id === tag.id ? { ...t, ...res.data } : t)));
      setNewTag({ name: '', color: '', description: '' });
      setConfirmingDelete(false);
      await refetchTags();
    } catch (error) {
      events.error({
        description: 'Ocorreu um erro ao atualizar o rótulo',
      });
      sendErrorToNewRelic(error);
    }
  };

  const handleDeleteTagFromList = async (id: string) => {
    try {
      await deleteTagFromList(id);
      setTagList(prevTagList => prevTagList.filter(tag => tag.id !== id));
      setNewTag({ name: '', color: '', description: '' });
      setConfirmingDelete(false);
      await refetchTags();
    } catch (error) {
      events.error({
        description: 'Ocorreu um erro ao tentar deletar o rótulo',
      });
      sendErrorToNewRelic(error);
    }
  };

  const handleNameChange = (name: string) => {
    setSelectedName(name);
  };

  return {
    confirmingDelete,
    setConfirmingDelete,
    selectedName,
    setSelectedName,
    selectedColor,
    setSelectedColor,
    handleSubmit,
    handleNameChange,
  };
}
