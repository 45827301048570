import { authService } from '@vdi/auth-service';
import { Flex, FloraButton, styled } from '@grupoboticario/flora-react';
import { ArrowsCircleIcon, ChatBubblePencilIcon, TwoPeopleCircleIcon } from '@grupoboticario/flora-react-icons';
import { useAccountStore, useChatStore, useContactStore, useTextElementStore } from '../../state';
import { useMessageSenderFormSubmit } from '@/features/MessageSender/hooks';
import { useSendMessage } from '@/shared/hooks';
import { events } from '@/shared/services/events';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Loading } from '../Loading';
import { useEffect, useState } from 'react';
import { MessageReceived, EventCommon } from '@/shared/services/api/instant-messenger/types';

const CopilotWrapper = styled('div', {
  boxShadow: 'inset $rightClose',
  padding: '$2 $4',
  overflowY: 'scroll',
  maxHeight: '100dvh',
  backgroundColor: '$backgroundSecondary',
});

const CopilotMessageList = styled('ul', {
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

const CopilotMessageItem = styled('li', {
  backgroundColor: '$nonInteractiveEmphasis',
  color: '$backgroundPrimary',
  borderRadius: '8px',
  padding: '$2',
  marginTop: '$3',
  boxShadow: '$downClose',
});

const CopilotMessageParagraph = styled('p', {
  margin: 0,
});

const CopilotSuggestingForMessage = styled('div', {
  backgroundColor: '$backgroundPrimary',
  borderRadius: '8px',
  padding: '$2',
  boxShadow: '$downClose',
});

export function Copilot() {
  const { contact } = useContactStore();
  const { account } = useAccountStore();
  const { onSendMessage } = useSendMessage(account, contact);
  const { textEl } = useTextElementStore();
  const { chat } = useChatStore();
  const [suggestionSent, setSuggestionSent] = useState(false);

  const { mutateAsync: markSuggestionAsUsed } = useMutation({
    onSuccess: () => {
      setSuggestionSent(true);
    },
    mutationFn: async ({ messageIndex, assistantId }: { messageIndex: number; assistantId: string }) => {
      const { token } = await authService.getTokenOrRedirect();
      return fetch(`${process.env.API_URL}/v1/chats/${chat?.id}/copilots/${assistantId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          chosenMessage: +messageIndex,
          editedMessage: 'test',
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      });
    },
  });

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['copilot', chat?.id],
    enabled: !!chat?.id,
    queryFn: async () => {
      const { token } = await authService.getTokenOrRedirect();
      const res = await fetch(`${process.env.API_URL}/v1/chats/${chat?.id}/copilots`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.json();
    },
  });

  useEffect(() => {
    function messageReceivedHander(event: MessageReceived & EventCommon) {
      refetch();
      setSuggestionSent(false);
    }

    // @ts-expect-error asd
    events.on('RECEIVED_COPILOT', messageReceivedHander);
    return () => {
      // @ts-expect-error asd
      events.off('RECEIVED_COPILOT', messageReceivedHander);
    };
  }, [contact?.id, refetch]);

  const handleSendNowOnClick = async ({ text, messageIndex }: { text: string; messageIndex: number }) => {
    const userName = authService.userData.name;
    const body = `*${userName}*:\n${text}`;

    try {
      await onSendMessage({
        provider: 'whatsapp',
        type: 'text',
        text: {
          body,
        },
      });
      await markSuggestionAsUsed({ messageIndex, assistantId: data?.id });
    } catch (error) {
      events.error({ description: 'Erro ao enviar mensagem de texto' });
    }
  };

  const onFormSubmit = async formValue => {
    /*
      Não enviar mensagem se o objeto formValue estiver vazio
      Não enviar mensagem a propriedade textMessage do objeto formValue estiver vazio
      Pra enviar a mensagem é necessário que o objeto formValue tenha pelo menos a chave textMessage com um valor
    */
    const text = formValue?.textMessage;
    const hasText = text?.trim()?.length > 0;
    if (!hasText) return;

    const userName = authService.userData.name;
    const body = `*${userName}*:\n${text}`;

    try {
      await onSendMessage({
        provider: 'whatsapp',
        type: 'text',
        text: {
          body,
        },
      });
    } catch (error) {
      events.error({ description: 'Erro ao enviar mensagem de texto' });
    }
  };

  const { setToEdit } = useMessageSenderFormSubmit({
    isRequired: true,
    ref: textEl,
    registerName: 'textMessage',
    onFormSubmit,
  });

  if (suggestionSent) {
    return (
      <Flex justify="center" align="center" direction="column" css={{ padding: '$2 $4', textAlign: 'center' }}>
        Sugestão enviada com sucesso!
      </Flex>
    );
  }

  if (!contact || !chat) {
    return (
      <Flex justify="center" align="center" direction="column" css={{ padding: '$2 $4', textAlign: 'center' }}>
        <TwoPeopleCircleIcon size={48} />
        Olá, {authService.userData.name}, eu sou a VDI Copilot! <br />
        <br /> Selecione um contato para eu te ajudar.
      </Flex>
    );
  }

  if (isLoading || isRefetching) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        css={{ boxShadow: 'inset $rightClose', padding: '$2 $4', textAlign: 'center' }}
      >
        <h6>Carregando o seu assistente de atendimento</h6>
        <Loading css={{ width: '100%', margin: '$4 0' }} />
      </Flex>
    );
  }

  if (!data) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        css={{ boxShadow: 'inset $rightClose', padding: '$2 $4', textAlign: 'center' }}
      >
        <h6>Aguardando mensagens...</h6>
      </Flex>
    );
  } else {
    return (
      <CopilotWrapper>
        <Flex align="center" css={{ padding: '$8 0', color: '$nonInteractiveEmphasis' }}>
          <ChatBubblePencilIcon css={{ marginRight: '$2', color: '$nonInteractiveEmphasis' }} />
          <h5 style={{ margin: 0 }}>Algumas sugestões de resposta</h5>
        </Flex>
        <CopilotMessageList>
          {data?.suggestedMessages?.map((item, index) => {
            return (
              <CopilotMessageItem key={item}>
                <CopilotMessageParagraph>{item}</CopilotMessageParagraph>
                <Flex direction="row" justify="space-between" gapX="$4">
                  <FloraButton
                    css={{ marginTop: '$2' }}
                    size="small"
                    surfaceColor="dark"
                    hierarchy="secondary"
                    styleSemantic="neutral"
                    onClick={() => {
                      setToEdit(`${item}`);
                    }}
                    isFull
                  >
                    Editar antes de enviar
                  </FloraButton>
                  <FloraButton
                    css={{ marginTop: '$2' }}
                    size="small"
                    surfaceColor="dark"
                    hierarchy="secondary"
                    styleSemantic="success"
                    onClick={() => handleSendNowOnClick({ text: `${item}`, messageIndex: index })}
                    isFull
                  >
                    Enviar imediatamente
                  </FloraButton>
                </Flex>
              </CopilotMessageItem>
            );
          })}
        </CopilotMessageList>
        <FloraButton css={{ marginTop: '$2' }} hierarchy="tertiary" styleSemantic="neutral" isFull>
          Me envie novas sugestões <ArrowsCircleIcon css={{ '& svg': { width: '14px', height: '14px' } }} />
        </FloraButton>
      </CopilotWrapper>
    );
  }
}
