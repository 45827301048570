import { useMemo } from 'react';
import { SkeletonLoadingBox } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';
import { CopilotMain } from './main';

function ProductItemLoadingCard({ length = 2 }: { length?: number }) {
  const loadingComponentsLength = useMemo(() => Array.from({ length }), [length]);

  return loadingComponentsLength.map((_, index) => (
    <Flex
      key={index}
      direction="column"
      css={{
        backgroundColor: '$backgroundPrimary',
        borderRadius: '$medium',
        padding: '$4',
      }}
      gapY="$2"
    >
      <SkeletonLoadingBox maxWidth="$16" />
      <SkeletonLoadingBox />
    </Flex>
  ));
}

export function LoadingState() {
  return (
    <CopilotMain gapY="$4" css={{ padding: '$4', backgroundColor: '$backgroundSecondary' }}>
      <ProductItemLoadingCard />
    </CopilotMain>
  );
}
