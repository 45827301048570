import { getResellerCredits } from '@/shared/services/api/ca/services';
import { Reseller } from '@/shared/types/interfaces';
import { useQuery } from '@tanstack/react-query';

export const useResellerCredits = ({ vdId }: { vdId: Reseller['vdId'] }) => {
  return useQuery({
    queryKey: ['resellerCredits', vdId],
    queryFn: ({ signal }) => getResellerCredits({ vdId, signal }),
    enabled: !!vdId,
    placeholderData: {
      actualCyclePurchases: 0,
      availableCredit: 0,
      totalCredit: 0,
      balanceCCR: 0,
    },
    staleTime: 60 * 60 * 1000, // 1 horas
    gcTime: 60 * 60 * 1000, // 1 horas
  });
};
