import { useFilters } from '@/shared/hooks';
import { FloraButton } from '@grupoboticario/flora-react';

export const ResetFiltersButton = () => {
  const { isFilterEmpty, resetFilter } = useFilters();

  if (isFilterEmpty()) {
    return null;
  }

  return (
    <FloraButton
      css={{ textDecoration: 'underline', fontSize: '$auxiliar' }}
      size={'small'}
      hierarchy={'tertiary'}
      onClick={resetFilter}
    >
      remover filtros
    </FloraButton>
  );
};
