import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Account, Chat, Reseller } from '../types/interfaces';
import { TErrorMessage } from '@/features/Copilot/product/types';
import { emptyError } from '@/features/Copilot/product/errorMessage';

interface AccountState {
  account: Account;
  setAccount: (account: Account) => void;
}

interface ChatState {
  chat?: Chat;
  setChat: (chat: Chat) => void;
}

interface ErrorMessageState {
  error: TErrorMessage;
  setError: (error: TErrorMessage) => void;
}

interface ContactState {
  contact?: Reseller;
  setContact: (contact: Reseller) => void;
}

export const useAccountStore = create<AccountState>()(
  devtools(set => ({
    account: undefined,
    setAccount: account => set(() => ({ account })),
  })),
);

export const useChatStore = create<ChatState>()(
  devtools(set => ({
    chat: undefined,
    setChat: chat => set(() => ({ chat })),
  })),
);

export const useContactStore = create<ContactState>()(
  devtools(set => ({
    contact: undefined,
    setContact: contact => set(() => ({ contact })),
  })),
);

export const useErrorMessageStore = create<ErrorMessageState>()(
  devtools(set => ({
    error: emptyError,
    setError: error => set(() => ({ error })),
  })),
);
