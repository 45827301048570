import { authService } from '@vdi/auth-service';

export async function deleteCopilot({ chatId, copilotId }: { chatId: string; copilotId: string }) {
  const { token } = await authService.getTokenOrRedirect();
  try {
    await fetch(`${process.env.API_URL}/v1/chats/${chatId}/copilots/${copilotId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Failed to delete copilot:', error);
    throw error;
  }
}
