import { SkeletonLoadingBox } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export const ChatItemSkeleton = () => {
  return (
    <Flex direction="column" gap={'$2'} css={{ padding: '$3 $4', borderBottom: '1px solid $nonInteractiveOutline' }}>
      <SkeletonLoadingBox maxWidth="30%" height="$3" marginLeft="$7" />
      <Flex direction={'row'}>
        <SkeletonLoadingBox maxWidth="$5" />
        <SkeletonLoadingBox maxWidth="70%" marginLeft="$2" />
        <SkeletonLoadingBox maxWidth="20%" marginLeft="$2" />
      </Flex>
      <Flex direction={'row'}>
        <SkeletonLoadingBox maxWidth="90%" marginLeft="$7" />
        <SkeletonLoadingBox maxWidth="10%" marginLeft="$2" />
      </Flex>
    </Flex>
  );
};
