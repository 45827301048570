import { Copilots } from '@/features/Copilot/product-rupture/types';
import { authService } from '@vdi/auth-service';
import { mock } from '@/features/Copilot/product-rupture/mock';

export async function getCopilots({ signal, chatId }: { signal: AbortSignal; chatId: string }): Promise<Copilots> {
  // return Promise.resolve(mock);
  const { token } = await authService.getTokenOrRedirect();
  try {
    const res = await fetch(`${process.env.API_URL}/v1/chats/${chatId}/copilots`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Error fetching copilots:', error);
    throw error;
  }
}
