import { styled, Grid, GridProps } from '@grupoboticario/flora-react';
import { FC } from 'react';

type ChatWrapperProps = {
  copilotEnabled?: boolean;
};

export const ChatWrapper = styled(Grid, {
  gridTemplateRows: '100dvh',
  variants: {
    copilotEnabled: {
      true: {
        gridTemplateColumns: '3fr 6fr 3fr',
      },
      false: {
        gridTemplateColumns: '4fr 8fr',
      },
    },
  },
}) as FC<GridProps & ChatWrapperProps>;
