import { Tag } from '@/shared/types/interfaces/tag';
import { DropdownSingleSelector } from '@/shared/components';
import { TagFullIcon } from '@grupoboticario/flora-react-icons';
import { Box, Tooltip } from '@grupoboticario/flora-react';
import { useFetchTagList } from '@/features/ChatList/hooks';
import { useFilters } from '@/shared/hooks';

const buildTagIcon = (tag: Tag) => {
  return <TagFullIcon css={{ color: tag.color }} size="15" />;
};

const mapTagsToDropdownOptions = (tags: Tag[]) => {
  return tags.map(tag => ({
    label: tag.name,
    value: tag.id,
    icon: buildTagIcon(tag),
  }));
};

export const TagSelect = () => {
  const { data: tags, isLoading: isTagListLoading } = useFetchTagList({ enabled: true });
  const { tagFilter, updateTagFilter, isLoadingChats } = useFilters();

  const isEmptyData = tags?.data.length === 0;

  const dropdownSingleSelector = (
    <DropdownSingleSelector
      id="tag-select"
      label="Etiqueta:"
      altLabel="Filtrar por etiqueta"
      disabled={isEmptyData || isTagListLoading || isLoadingChats}
      options={mapTagsToDropdownOptions(tags?.data || [])}
      selectedValue={tagFilter}
      onSelect={updateTagFilter}
    />
  );

  if (isEmptyData) {
    return (
      <Tooltip
        align="center"
        direction="bottom"
        size="standard"
        text="Este filtro estará disponível quando você aplicar etiquetas às conversas"
      >
        <Box>{dropdownSingleSelector}</Box>
      </Tooltip>
    );
  }

  return dropdownSingleSelector;
};
