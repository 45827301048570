import { useEffect, useState } from 'react';
import { Flex } from '@grupoboticario/flora-react';
import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { Account, Reseller } from '@/shared/types/interfaces';
import { useFetchIsSubscribed } from '../../hooks/useFetchIsSubscribed';
import { MediaUploaderMenu } from '../MediaUploaderMenu';
import { TextFormMessageSender } from '../TextFormMessageSender';
import { TemplateFormMessageSender } from '../TemplateFormMessageSender';
import { UnsubscribedForm } from '../UnsubscribedForm';
import { Loading } from '@/shared/components';

// Verificao de toggle para habilitar a funcionalidade de consulta do opt-out
// Ao liberar para todas contas, podemos retirar.
const accountsEnabledSubscriptions = ['wxk_yltpvn', 'z9VxvWy-gZ', 'iae_fdymngt'];

interface MessageSenderSwitcherProps {
  isChatSessionOpen: boolean;
  sendMessageWithReply(payload: SendMessageData): Promise<void>;
  account: Account;
  contact: Reseller;
}

export const MessageSenderSwitcher = ({
  isChatSessionOpen,
  sendMessageWithReply,
  account,
  contact,
}: MessageSenderSwitcherProps): JSX.Element => {
  const [messageSenderType, setMessageSenderType] = useState(undefined);
  const subscriptionSearchEnabled = accountsEnabledSubscriptions.some(() => account.id);
  const { data: isSubscribed } = useFetchIsSubscribed({
    accountId: account.id,
    contactId: contact.id,
    enabled: subscriptionSearchEnabled,
  });

  useEffect(() => {
    if (isChatSessionOpen) {
      setMessageSenderType('interactive');
      return;
    }
    if (!subscriptionSearchEnabled || isSubscribed === true) {
      setMessageSenderType('template');
      return;
    }
    if (isSubscribed === false) {
      setMessageSenderType('unsubscribed');
      return;
    }
  }, [isChatSessionOpen, isSubscribed]);

  function senderSwitchers() {
    switch (messageSenderType) {
      case 'interactive':
        return (
          <Flex direction="row">
            <MediaUploaderMenu sendMessageWithReply={sendMessageWithReply} />
            <TextFormMessageSender sendMessageWithReply={sendMessageWithReply} />
          </Flex>
        );
      case 'template':
        return <TemplateFormMessageSender />;
      case 'unsubscribed':
        return <UnsubscribedForm />;
      default:
        return <Loading css={{ width: '100%', height: '100%' }} />;
    }
  }

  return <div>{senderSwitchers()}</div>;
};
