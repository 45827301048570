import { TErrorMessage } from './types';

export const emptyError: TErrorMessage = {
  message: '',
  type: '',
};

export const copyMessageError: TErrorMessage = {
  message: 'Erro ao copiar a mensagem',
  type: 'copyMessageError',
};

export const sendMessageError: TErrorMessage = {
  message: 'Erro ao enviar a mensagem',
  type: 'sendMessageError',
};

export const generateMessageError: TErrorMessage = {
  message: 'Erro ao gerar a mensagem',
  type: 'generateMessageError',
};
