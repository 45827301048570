import { useQuery } from '@tanstack/react-query';
import { getCopilots } from '@/shared/services/api/instant-messenger/service/getCopilots';

export function useCopilotList({ chatId }: { chatId: string }) {
  return useQuery({
    queryKey: ['copilot', chatId],
    enabled: !!chatId,
    queryFn: async ({ signal }) => getCopilots({ signal, chatId }),
    select(data) {
      const sortedData = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      return sortedData;
    },
  });
}
