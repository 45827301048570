import { Flex, Box, FloraTypography, Tag, FloraButton } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import { Copilot } from './types';

export function RupturedProductRequested({
  rupturedProduct,
  confirmDelete,
  setConfirmDelete,
}: {
  rupturedProduct: Copilot['ruptura']['product'];
  confirmDelete: boolean;
  setConfirmDelete: (value: boolean) => void;
}) {
  return (
    <Flex direction="row" wrap="nowrap" justify="space-between" css={{ flex: '1 1 auto' }}>
      <Box css={{ color: '$nonInteractiveAuxiliar' }}>
        <FloraTypography as="p" fontSize="exceptionsRestricted" css={{ margin: 0 }}>
          SKU: {rupturedProduct.sku}
          <Tag
            variant="disabled"
            size="small"
            css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
          >
            Sem estoque
          </Tag>
        </FloraTypography>
        <FloraTypography as="p" fontSize="exceptionsAuxiliar" css={{ margin: '$1 0 0 0' }}>
          {rupturedProduct.name}
        </FloraTypography>
      </Box>
      {!confirmDelete && (
        <FloraButton
          has="iconOnly"
          icon={<CrossIcon />}
          hierarchy="tertiary"
          styleSemantic="neutral"
          size="small"
          title="Fechar recomendação"
          onClick={() => setConfirmDelete(true)}
        />
      )}
    </Flex>
  );
}
