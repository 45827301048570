import { Box, keyframes } from '@grupoboticario/flora-react';

const skeletonAnimation = keyframes({
  to: {
    backgroundPositionX: '-20%',
  },
});

export function SkeletonLoadingBox({
  maxWidth = '100%',
  height = '$5',
  marginLeft = '0',
}: {
  maxWidth?: string;
  height?: string;
  marginLeft?: string;
}) {
  return (
    <Box
      css={{
        flex: '1 0 auto',
        maxWidth: maxWidth,
        height: height,
        marginLeft: marginLeft,
        backgroundColor: '$backgroundSecondary',
        background:
          'linear-gradient(100deg,rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) $backgroundSecondary',
        backgroundSize: '200% 100%',
        backgroundPositionX: '180%',
        animation: `1s ${skeletonAnimation} ease-in infinite`,
      }}
    >
      &nbsp;
    </Box>
  );
}
