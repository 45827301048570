import { useState } from 'react';
import { FloraButton } from '@grupoboticario/flora-react';
import { CheckSingleIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { htmlToMarkdown } from './ruptured-product-item';
import { copyMessageError } from './errorMessage';
import { useErrorMessageStore } from '@/shared/state';

export function SimilarProductCopyMessage({
  ruptureRecommendedMessage,
  recommendedMessageIsPending,
}: {
  ruptureRecommendedMessage: string;
  recommendedMessageIsPending: boolean;
}) {
  const { setError } = useErrorMessageStore();
  const [copied, setCopied] = useState(false);

  return (
    <FloraButton
      size="small"
      hierarchy="secondary"
      styleSemantic={copied ? 'success' : 'neutral'}
      has="iconRight"
      icon={copied ? <CheckSingleIcon /> : <TwoPiledSquaresIcon />}
      isFull
      disabled={recommendedMessageIsPending}
      onClick={() => {
        try {
          navigator.clipboard.writeText(htmlToMarkdown(ruptureRecommendedMessage));
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (error) {
          setError(copyMessageError);
        }
      }}
      css={{ pointerEvents: copied ? 'none' : 'auto' }}
    >
      {copied ? 'Copiada' : 'Copiar'}
    </FloraButton>
  );
}
