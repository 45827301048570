import { useEffect, useState } from 'react';
import { Box, Flex, Grid } from '@grupoboticario/flora-react';
import { MessageTypesSwitcher } from '@/features/MessagesList/components/MessageTypesSwitcher';
import { ButtonCancelReply, TextFormMessageSender, TemplateFormMessageSender, MediaUploaderMenu } from './components';
import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { events } from '@/shared/services/events';
import { Message } from '@/shared/services/api/instant-messenger/types';
import { MediaUploadProgress } from './components/MediaUploadProgress/MediaUploadProgress';
import { MessageSenderWrapper } from './components/MessageSenderWrapper';
import { ReplyerName } from '../MessagesList/components/MessageSwitcher/ReplyerName';
import { isAMessageFromSelectedContact } from '@/shared/functions';
import { useSendMessage } from '@/shared/hooks';
import { useAccountStore, useContactStore } from '@/shared/state';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

type MessageSenderProps = {
  isChatSessionOpen: boolean;
};

export function MessageSender({ isChatSessionOpen }: MessageSenderProps) {
  const [messageSelectedToReply, setMessageSelectedToReply] = useState<Message | undefined>(undefined);
  const { account } = useAccountStore();
  const { contact } = useContactStore();
  const { onSendMessage } = useSendMessage(account, contact);

  async function sendMessageWithReply(payload: SendMessageData) {
    try {
      await onSendMessage({ ...payload, reply: messageSelectedToReply });
      setMessageSelectedToReply(undefined);
    } catch (error) {
      events.error({ description: 'Erro ao enviar mensagem' });
      sendErrorToNewRelic(error);
    }
  }

  useEffect(() => {
    function messageSelectedHandler(message: Message) {
      setMessageSelectedToReply(message);
    }

    events.on('MESSAGE_SELECTED', messageSelectedHandler);
    return () => {
      events.off('MESSAGE_SELECTED', messageSelectedHandler);
    };
  }, [account?.id, contact?.id]);

  return (
    <MessageSenderWrapper isChatSessionOpen={isChatSessionOpen}>
      <MediaUploadProgress />
      <Grid align="flex-end" data-testid="send-message-form" gap="$3">
        {messageSelectedToReply && (
          <Flex
            direction="row"
            wrap="nowrap"
            align="center"
            style={{ margin: '-8px -8px 0 -8px', padding: '8px', backgroundColor: 'white' }}
          >
            <Box
              css={{
                width: '100%',
                padding: '8px',
                backgroundColor: '#f4f5f6',
                borderRadius: '5px',
                borderLeft: `4px solid ${isAMessageFromSelectedContact(messageSelectedToReply) ? '$actionableDefault' : '$backgroundAltTertiary'}`,
                fontSize: '14px',
                color: 'rgb(102, 119, 129)',
              }}
            >
              <ReplyerName
                isAMessageFromSelectedContact={isAMessageFromSelectedContact(messageSelectedToReply)}
                contactName={contact.name}
              />
              <Flex
                direction="column"
                wrap="nowrap"
                css={{
                  pointerEvents: 'none',
                  '& img, & video': { maxWidth: '190px !important', height: 'auto' },
                  '& a': { color: '$actionableDefault !important' },
                }}
              >
                <MessageTypesSwitcher message={messageSelectedToReply} />
              </Flex>
            </Box>
            <ButtonCancelReply setMessageSelectedToReply={setMessageSelectedToReply} />
          </Flex>
        )}
        {isChatSessionOpen ? (
          <Flex direction="row">
            <MediaUploaderMenu sendMessageWithReply={sendMessageWithReply} />
            <TextFormMessageSender sendMessageWithReply={sendMessageWithReply} />
          </Flex>
        ) : (
          <TemplateFormMessageSender />
        )}
      </Grid>
    </MessageSenderWrapper>
  );
}
