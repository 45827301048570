import { useFilters } from '@/shared/hooks';
import { Flex, FloraButton, FloraTypography } from '@grupoboticario/flora-react';
import { ChatBubbleIcon } from '@grupoboticario/flora-react-icons';

export const ChatListBlankState = () => {
  const { resetFilter } = useFilters();

  return (
    <Flex direction="column" align="center" justify="center" gap="$4" css={{ flexGrow: 1 }}>
      <Flex
        align="center"
        justify="center"
        css={{ width: '88px', height: '88px', backgroundColor: '$backgroundSecondary', borderRadius: '100%' }}
      >
        <ChatBubbleIcon size="32px" />
      </Flex>
      <Flex direction="column">
        <FloraTypography as="h3" fontSize="bodyLargeShort" css={{ textAlign: 'center' }}>
          Sem conversas para exibir
        </FloraTypography>
        <FloraTypography
          as="p"
          fontSize="bodySmallStandard"
          css={{ textAlign: 'center', margin: '0', color: '$nonInteractiveAuxiliar' }}
        >
          Remova ou escolha outros filtros
        </FloraTypography>
      </Flex>

      <FloraButton onClick={resetFilter}>Remover filtros</FloraButton>
    </Flex>
  );
};
