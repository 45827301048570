import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ShineIcon } from '@grupoboticario/flora-react-icons';

export function BlankState({ title, message }: { title?: string; message?: string }) {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        css={{ width: '88px', height: '88px', backgroundColor: '$backgroundSecondary', borderRadius: '100%' }}
      >
        <ShineIcon size="32px" />
      </Flex>
      <FloraTypography as="h3" fontSize="bodyLargeShort" css={{ margin: '$4 0 0 0' }}>
        {title}
      </FloraTypography>
      <FloraTypography
        as="p"
        fontSize="bodySmallStandard"
        css={{ margin: '$2 0 0 0', color: '$nonInteractiveAuxiliar' }}
      >
        {message}
      </FloraTypography>
    </>
  );
}
