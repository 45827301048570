import { queryClient } from '@/shared/services/api/queryClient';
import { useMutation } from '@tanstack/react-query';
import { authService } from '@vdi/auth-service';
import { Copilots } from '../types';
import { useChatStore } from '@/shared/state';

export default function useRateCopilot({ copilotId }: { copilotId: string }) {
  const { chat } = useChatStore();

  return useMutation({
    mutationKey: ['chats', chat?.id, 'copilot', copilotId, 'feedback'],
    mutationFn: async ({
      chatId,
      copilotId,
      feedback,
    }: {
      chatId: string;
      copilotId: string;
      feedback: -1 | 0 | 1;
    }) => {
      const { token } = await authService.getTokenOrRedirect();
      await fetch(`${process.env.API_URL}/v1/chats/${chatId}/copilots/${copilotId}/feedback`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback }),
      });
    },
    onMutate(variables) {
      queryClient.setQueryData<Copilots>(['copilot', variables.chatId], copilotInCache => {
        return copilotInCache.map(copilot => {
          return {
            ...copilot,
            feedback: copilot.id === variables.copilotId ? variables.feedback : copilot.feedback,
          };
        });
      });
    },
    onSuccess(_, variables) {
      queryClient.invalidateQueries({ queryKey: ['copilot', variables.chatId] });
    },
  });
}
