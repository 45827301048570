import { ProductList } from './product-list';
import { useEffect } from 'react';
import { events } from '@/shared/services/events';
import { useDeleteCopilot } from '../hooks/useDeleteCopilot';
import { Copilot } from '../types';
import { LoadingState } from '../loading-state';
import { ErrorState } from '../error-state';
import { trackInteractionEvent } from '@/shared/services/eventTracker/trackerService';
import { loadingRecommendationsEvent } from '@/shared/services/eventTracker/events/events';

export function Product({
  chatId,
  copilots,
  isLoading,
  isError,
  refetch,
}: {
  chatId: string;
  copilots: Copilot[];
  isLoading: boolean;
  isError: boolean;
  refetch: any;
}) {
  useEffect(() => {
    events.on('COPILOT_RECEIVED', () => {
      setTimeout(() => {
        refetch();
      }, 2000);
    });

    return () => {
      events.off('COPILOT_RECEIVED');
    };
  }, []);

  const { mutate: deleteCopilot } = useDeleteCopilot();

  if (isLoading) {
    trackInteractionEvent(loadingRecommendationsEvent);

    return <LoadingState />;
  }

  if (isError) {
    return <ErrorState />;
  }

  if (copilots) {
    return <ProductList copilots={copilots} chatId={chatId} deleteCopilot={deleteCopilot} />;
  }
}
