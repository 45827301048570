import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ShineIcon } from '@grupoboticario/flora-react-icons';
import { ProductRuptureMain } from './main';
import { ProductRuptureWrapper } from './wrapper';

export function BlankState() {
  return (
    <ProductRuptureWrapper>
      <ProductRuptureMain align="center" justify="center" css={{ padding: '0 $8', textAlign: 'center' }}>
        <Flex
          align="center"
          justify="center"
          css={{ width: '88px', height: '88px', backgroundColor: '$backgroundSecondary', borderRadius: '100%' }}
        >
          <ShineIcon size="32px" />
        </Flex>
        <FloraTypography as="h3" fontSize="bodyLargeShort" css={{ margin: '$4 0 0 0' }}>
          Nenhum produto identificado
        </FloraTypography>
        <FloraTypography
          as="p"
          fontSize="bodySmallStandard"
          css={{ margin: '$2 0 0 0', color: '$nonInteractiveAuxiliar' }}
        >
          Solicite o código (SKU) à pessoa revendedora para pesquisar automaticamente
        </FloraTypography>
      </ProductRuptureMain>
    </ProductRuptureWrapper>
  );
}
