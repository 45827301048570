import { Toaster } from '@grupoboticario/flora-react';
import { useEffect } from 'react';
import { events } from '@/shared/services/events';

declare const newrelic: any;

export type ToastPayloadType = Parameters<typeof Toaster.notify>[0];

export type MessageType = Pick<ToastPayloadType, 'kind' | 'description' | 'duration' | 'button'>;

export const Notify = (): JSX.Element | null => {
  useEffect(() => {
    events.on('NOTIFICATION', options => {
      const mergedOptions: ToastPayloadType = {
        button: 'Fechar',
        closeWhenClick: true,
        duration: 6000,
        origin: 'right-top',
        ...options,
      };

      Toaster.notify(mergedOptions);

      if (typeof newrelic === 'function') {
        newrelic.addPageAction('notification', mergedOptions);
      }
    });

    return function cleanup() {
      events.all.clear();
    };
  }, []);

  return null;
};
