import { useMemo } from 'react';
import { Box, Flex, keyframes } from '@grupoboticario/flora-react';
import { ProductRuptureMain } from './main';
import { ProductRuptureWrapper } from './wrapper';

const loadingSkeleton = keyframes({
  to: {
    backgroundPositionX: '-20%',
  },
});

function LoadingBox({ maxWidth = '100%' }: { maxWidth?: string }) {
  return (
    <Box
      css={{
        flex: '0 0 auto',
        maxWidth,
        height: '1rem',
        backgroundColor: '$backgroundSecondary',
        background:
          'linear-gradient(100deg,rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) var(--flora--colors-backgroundSecondary)',
        backgroundSize: '200% 100%',
        backgroundPositionX: '180%',
        animation: `1s ${loadingSkeleton} ease-in infinite`,
      }}
    />
  );
}

function ProductItemLoadingCard({ length = 2 }: { length?: number }) {
  const loadingComponentsLength = useMemo(() => Array.from({ length }), [length]);

  return loadingComponentsLength.map((component, index) => (
    <Flex
      key={index}
      direction="column"
      css={{
        backgroundColor: '$backgroundPrimary',
        borderRadius: '$medium',
        padding: '$4',
      }}
      gapY="$2"
    >
      <LoadingBox maxWidth="$16" />
      <LoadingBox />
    </Flex>
  ));
}

export function LoadingState() {
  return (
    <ProductRuptureWrapper>
      <ProductRuptureMain gapY="$4" css={{ padding: '$4', backgroundColor: '$backgroundSecondary' }}>
        <ProductItemLoadingCard />
      </ProductRuptureMain>
    </ProductRuptureWrapper>
  );
}
