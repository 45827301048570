import { Flex, Box, FloraTypography, Tag, Image } from '@grupoboticario/flora-react';
import { Image1Icon } from '@grupoboticario/flora-react-icons';
import { Copilot } from './types';

export function RupturedProductRecommendedDescription({
  similarProduct,
}: {
  similarProduct: Copilot['ruptura']['similar'];
}) {
  return (
    <Flex direction="row" align="center" wrap="nowrap">
      <Box css={{ flex: '0 1 auto', minWidth: '40px', marginRight: '$4' }}>
        {similarProduct?.images?.thumbnail ? (
          <a href={`${similarProduct.images.extraLarge}`} target="_blank" rel="noreferrer">
            <Image size="40px" src={similarProduct.images.thumbnail} alt={similarProduct.name} loading="lazy" />
          </a>
        ) : (
          <Image1Icon size="40px" />
        )}
      </Box>
      <Flex direction="column" css={{ flex: '1 1 auto', color: '#222' }}>
        <FloraTypography as="p" fontSize="exceptionsRestricted" css={{ margin: '0' }}>
          SKU: {similarProduct.sku}
          {similarProduct.availability === 'warning' && (
            <Tag
              variant="alert"
              size="small"
              css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
            >
              Quase esgotado
            </Tag>
          )}
        </FloraTypography>
        <FloraTypography as="p" fontSize="exceptionsAuxiliar" css={{ margin: '$1 0 0 0' }}>
          {similarProduct.name}
        </FloraTypography>
        <FloraTypography as="p" fontSize="exceptionsAuxiliar" css={{ margin: '$1 0 0 0', fontVariant: 'tabular-nums' }}>
          R$ {similarProduct.price}
        </FloraTypography>
      </Flex>
    </Flex>
  );
}
