import { createContext, ReactNode, useContext, useState } from 'react';

type ChatListResultData = {
  totalCount: number;
  filteredCount?: number;
};

interface FiltersContextData {
  unreadFilter: boolean;
  tagFilter: string;
  isLoadingChats: boolean;
  chatListResultData?: ChatListResultData;
  isFilterEmpty: () => boolean;
  resetFilter: () => void;
  updateUnreadFilter: () => void;
  updateTagFilter: (tagFilter: string) => void;
  updateLoadingChats: (isLoading: boolean) => void;
  updateChatListResultData: (data: ChatListResultData) => void;
}

interface FiltersProviderProps {
  children: ReactNode;
}

export const FiltersContext = createContext<FiltersContextData>({} as FiltersContextData);

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const [unreadFilter, setUnreadFilter] = useState(false);
  const [tagFilter, setTagFilter] = useState('');

  const [chatListResultData, setChatListResultData] = useState<ChatListResultData>(null);
  const [isLoadingChats, setIsLoadingChats] = useState(false);

  const resetFilter = () => {
    setUnreadFilter(false);
    setTagFilter('');
  };

  const isFilterEmpty = () => {
    return !unreadFilter && tagFilter === '';
  };

  const updateUnreadFilter = () => {
    setUnreadFilter(!unreadFilter);
  };

  const updateTagFilter = (tagFilter: string) => {
    setTagFilter(tagFilter);
  };

  const updateLoadingChats = (isLoading: boolean) => {
    setIsLoadingChats(isLoading);
  };

  const updateChatListResultData = (data: ChatListResultData) => {
    setChatListResultData(data);
  };

  return (
    <FiltersContext.Provider
      value={{
        unreadFilter,
        tagFilter,
        isLoadingChats,
        chatListResultData,
        isFilterEmpty,
        resetFilter,
        updateUnreadFilter,
        updateTagFilter,
        updateLoadingChats,
        updateChatListResultData,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
