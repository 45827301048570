import { useChatStore } from '@/shared/state';
import { BlankState } from './blank-state';
import { LoadingState } from './loading-state';
import { RupturedProductList } from './ruptured-product-list';
import { ErrorState } from './error-state';
import { useCopilotList } from './hooks/useCopilotList';
import { useEffect } from 'react';
import { events } from '@/shared/services/events';
import { useDeleteCopilot } from './hooks/useDeleteCopilot';

export function ProductRupture() {
  const { chat } = useChatStore();
  const { data: copilots, isLoading, isError, refetch, isRefetching } = useCopilotList({ chatId: chat?.id });

  useEffect(() => {
    events.on('*', event => {
      console.log('olar', event);
    });
    events.on('COPILOT_RECEIVED', () => {
      setTimeout(() => {
        refetch();
      }, 2000);
    });

    return () => {
      events.off('COPILOT_RECEIVED');
    };
  }, []);

  const { mutate: deleteRecommendation } = useDeleteCopilot();

  /**
   *
   * Quando a lista de produtos com ruptura está carregando
   */
  if (isLoading) {
    return <LoadingState />;
  }

  /**
   *
   * Quando não há chat selecionado ou
   * não há mais item na lista de rupturas
   * exibe o BlankState
   */
  if (!chat?.id || copilots?.length === 0) {
    return <BlankState />;
  }

  /**
   *
   * Quando ocorre um erro ao buscar a lista de produtos com ruptura
   */
  if (isError) {
    return <ErrorState />;
  }

  if (copilots) {
    return (
      <RupturedProductList
        rupturedProducts={copilots}
        chatId={chat.id}
        deleteRecommendation={deleteRecommendation}
        isRefetching={isRefetching}
      />
    );
  }
}
