import { Box, Flex, FloraButton, FloraTypography, keyframes } from '@grupoboticario/flora-react';
import {
  PaperAirplaneIcon,
  ShineIcon,
  ThumbDownOutlineIcon,
  ThumbUpOutlineIcon,
} from '@grupoboticario/flora-react-icons';
import DOMPurify from 'dompurify';
import { SimilarProductCopyMessage } from './similar-product-copy-message';
import { useState } from 'react';
import { queryClient } from '@/shared/services/api/queryClient';
import { useDeleteCopilot } from './hooks/useDeleteCopilot';

const loadingSkeleton = keyframes({
  to: {
    backgroundPositionX: '-20%',
  },
});

const skeletonAnimation = {
  backgroundColor: 'rgba(0,0,0,.05)',
  background:
    'linear-gradient(100deg,rgba(255, 255, 255, 0.05) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0.05) 60%) rgba(0,0,0,.05)',
  backgroundSize: '200% 100%',
  backgroundPositionX: '180%',
  animation: `1s ${loadingSkeleton} ease-in infinite`,
};

export function SimilarProductRecommendedMessage({
  ruptureRecommendedMessage,
  recommendedMessageSent,
  ruptureRecommendedMessageFeedback,
  sendFeedback,
  chatId,
  ruptureId,
  sendNow,
  recommendedMessageIsPending,
}: {
  ruptureRecommendedMessage: string;
  recommendedMessageSent: boolean;
  ruptureRecommendedMessageFeedback: number;
  sendFeedback: ({ feedback, chatId, copilotId }: { feedback: number; chatId: string; copilotId: string }) => void;
  chatId: string;
  ruptureId: string;
  sendNow: () => void;
  recommendedMessageIsPending: boolean;
}) {
  const { mutate: deleteRecommendation, isSuccess: copilotSuccessfullyDeleted } = useDeleteCopilot();

  const [requestedRuptureRecommendedMessage, setRequestedRuptureRecommendedMessage] = useState(false);
  const [fakeLoadingRuptureRecommendedMessage, setFakeLoadingRuptureRecommendedMessage] = useState(false);

  function handleGenerateMessage() {
    setFakeLoadingRuptureRecommendedMessage(true);

    setTimeout(
      () => {
        setRequestedRuptureRecommendedMessage(true);
        queryClient.invalidateQueries({ queryKey: ['copilot', chatId] }).then(() => {
          setFakeLoadingRuptureRecommendedMessage(false);
        });
      },
      ruptureRecommendedMessage ? 2000 : 5000,
    );
  }

  if (!requestedRuptureRecommendedMessage || (requestedRuptureRecommendedMessage && !ruptureRecommendedMessage)) {
    if (fakeLoadingRuptureRecommendedMessage) {
      return (
        <Flex
          direction="column"
          css={{ marginTop: '$4', padding: '$4', backgroundColor: '$backgroundSecondary', borderRadius: '$medium' }}
        >
          <Flex direction="row" align="center" gapX="$2">
            <FloraTypography
              as="span"
              fontSize="exceptionsRestricted"
              fontWeight="regular"
              css={{ color: '$nonInteractiveAuxiliar' }}
            >
              Gerando a mensagem…
            </FloraTypography>
            <Box
              css={{
                width: '32px',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,.05)',
                borderRadius: '$small',
                marginLeft: 'auto',
                ...skeletonAnimation,
              }}
            />
            <Box
              css={{
                ...skeletonAnimation,
                width: '32px',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,.05)',
                borderRadius: '$small',
              }}
            />
          </Flex>
          <Flex direction="column" gapY="$2" css={{ marginTop: '$2' }}>
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '80%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
          </Flex>
          <Flex direction="row" justify="space-between" gapX="$2" css={{ marginTop: '$4' }}>
            <Box
              css={{
                ...skeletonAnimation,
                flex: '1 1 auto',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '$small',
              }}
            />
            <Box
              css={{
                ...skeletonAnimation,
                flex: '1 1 auto',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '$small',
              }}
            />
          </Flex>
        </Flex>
      );
    }
    return (
      <FloraButton
        has="iconRight"
        icon={<ShineIcon />}
        hierarchy="secondary"
        styleSemantic="neutral"
        size="small"
        isFull
        css={{ marginTop: '$4' }}
        onClick={handleGenerateMessage}
        isLoading={fakeLoadingRuptureRecommendedMessage}
        disabled={fakeLoadingRuptureRecommendedMessage}
      >
        Gerar mensagem
      </FloraButton>
    );
  }

  if (!recommendedMessageSent) {
    return (
      <Flex
        direction="column"
        wrap="wrap"
        css={{
          marginTop: '$4',
          padding: '$3',
          backgroundColor: '$backgroundSecondary',
          borderRadius: '$medium',
          boxShadow: '$downClose',
        }}
      >
        <Flex direction="row" wrap="nowrap" align="center" justify="space-between">
          <FloraTypography
            as="span"
            fontSize="exceptionsRestricted"
            css={{ flex: '1 1 auto', color: '$nonInteractiveAuxiliar' }}
          >
            Recomendação de mensagem
          </FloraTypography>
          <FloraButton
            has="iconOnly"
            size="small"
            hierarchy="secondary"
            styleSemantic={ruptureRecommendedMessageFeedback === 1 ? 'success' : 'neutral'}
            icon={<ThumbUpOutlineIcon />}
            css={{
              backgroundColor: `${ruptureRecommendedMessageFeedback === 1 && '$statusSuccessNonPrimaryButtonActive'}`,
              pointerEvents: `${ruptureRecommendedMessageFeedback === 1 ? 'none' : 'auto'}`,
            }}
            onClick={() => sendFeedback({ chatId, copilotId: ruptureId, feedback: 1 })}
          />
          <FloraButton
            has="iconOnly"
            size="small"
            hierarchy="secondary"
            styleSemantic={ruptureRecommendedMessageFeedback === -1 ? 'warning' : 'neutral'}
            icon={<ThumbDownOutlineIcon />}
            css={{
              backgroundColor: `${ruptureRecommendedMessageFeedback === -1 && '$statusErrorNonPrimaryButtonActive'}`,
              pointerEvents: `${ruptureRecommendedMessageFeedback === -1 ? 'none' : 'auto'}`,
              marginLeft: '$2',
            }}
            onClick={() => sendFeedback({ chatId, copilotId: ruptureId, feedback: -1 })}
          />
        </Flex>
        <FloraTypography
          as="p"
          fontSize="bodySmallStandard"
          css={{ margin: '$2 0 0 0' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(ruptureRecommendedMessage),
          }}
        />
        <Flex direction="row" wrap="no-wrap" gapX="$2" css={{ marginTop: '$4' }}>
          <FloraButton
            size="small"
            hierarchy="primary"
            styleSemantic="neutral"
            has="iconRight"
            icon={<PaperAirplaneIcon />}
            isFull
            onClick={sendNow}
            disabled={recommendedMessageIsPending}
            isLoading={recommendedMessageIsPending}
          >
            Enviar
          </FloraButton>
          <SimilarProductCopyMessage
            ruptureRecommendedMessage={ruptureRecommendedMessage}
            recommendedMessageIsPending={recommendedMessageIsPending}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        wrap="wrap"
        css={{
          marginTop: '$4',
          padding: '$3',
          backgroundColor: '$backgroundSecondary',
          borderRadius: '$medium',
          boxShadow: '$downClose',
        }}
      >
        <Flex direction="column">
          <FloraTypography fontSize="bodySmallStandard" fontWeight="medium">
            Mensagem enviada!
          </FloraTypography>
          <Flex direction="row" wrap="nowrap" align="center" justify="space-between">
            <FloraTypography
              as="span"
              fontSize="exceptionsRestricted"
              css={{ flex: '1 1 auto', color: '$nonInteractiveAuxiliar' }}
            >
              O que achou do texto recomendado?
            </FloraTypography>
            <FloraButton
              has="iconOnly"
              size="small"
              hierarchy="secondary"
              styleSemantic={ruptureRecommendedMessageFeedback === 1 ? 'success' : 'neutral'}
              icon={<ThumbUpOutlineIcon />}
              css={{
                backgroundColor: `${ruptureRecommendedMessageFeedback === 1 && '$statusSuccessNonPrimaryButtonActive'}`,
                pointerEvents: `${ruptureRecommendedMessageFeedback === 1 ? 'none' : 'auto'}`,
              }}
              onClick={() => {
                sendFeedback({ chatId, copilotId: ruptureId, feedback: 1 });
                if (recommendedMessageSent) {
                  setTimeout(() => {
                    deleteRecommendation({ chatId, copilotId: ruptureId });
                  }, 5000);
                }
              }}
            />
            <FloraButton
              has="iconOnly"
              size="small"
              hierarchy="secondary"
              styleSemantic={ruptureRecommendedMessageFeedback === -1 ? 'warning' : 'neutral'}
              icon={<ThumbDownOutlineIcon />}
              css={{
                backgroundColor: `${ruptureRecommendedMessageFeedback === -1 && '$statusErrorNonPrimaryButtonActive'}`,
                pointerEvents: `${ruptureRecommendedMessageFeedback === -1 ? 'none' : 'auto'}`,
                marginLeft: '$2',
              }}
              onClick={() => {
                sendFeedback({ chatId, copilotId: ruptureId, feedback: -1 });
                if (recommendedMessageSent) {
                  setTimeout(() => {
                    deleteRecommendation({ chatId, copilotId: ruptureId });
                  }, 5000);
                }
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {recommendedMessageSent && ruptureRecommendedMessageFeedback !== 0 && (
        <FloraTypography
          fontSize="bodySmallStandard"
          fontWeight="regular"
          css={{ textAlign: 'center', margin: '$4 0 0 0' }}
        >
          Removendo em 5 segundos…
        </FloraTypography>
      )}
    </>
  );
}
