import { useChatStore } from '@/shared/state';
import { CopilotHeader } from './copilot-header';
import { CopilotWrapper } from './copilot-wrapper';
import { Product } from './product';
import { useCopilotList } from './hooks/useCopilotList';
import { BlankState } from './blank-state';
import { CopilotMain } from './main';

export function Copilot() {
  const { chat } = useChatStore();
  const { data: copilots, isLoading, isError, refetch } = useCopilotList({ chatId: chat?.id });
  const hasCopilots = copilots?.length > 0;
  const isChatActive = !!chat?.id;

  return (
    <CopilotWrapper>
      <CopilotHeader />
      {!isChatActive && (
        <CopilotMain align="center" justify="center" css={{ padding: '0 $8', textAlign: 'center' }}>
          <BlankState title={'Nenhum chat selecionado'} />
        </CopilotMain>
      )}
      {isChatActive && !hasCopilots && (
        <CopilotMain align="center" justify="center" css={{ padding: '0 $8', textAlign: 'center' }}>
          <BlankState
            title={'Nenhum produto identificado'}
            message={'Solicite o código (SKU) à pessoa revendedora para pesquisar automaticamente'}
          />
        </CopilotMain>
      )}
      {hasCopilots && (
        <CopilotMain css={{ backgroundColor: '$backgroundSecondary' }}>
          <Product chatId={chat.id} copilots={copilots} isLoading={isLoading} isError={isError} refetch={refetch} />
        </CopilotMain>
      )}
    </CopilotWrapper>
  );
}
