import { Flex } from '@grupoboticario/flora-react';
import { ReactNode } from 'react';
import { ProductRuptureHeader } from './header';

export function ProductRuptureWrapper({
  children,
  isRefetching = false,
}: {
  children: ReactNode;
  isRefetching?: boolean;
}) {
  return (
    <Flex direction="column" wrap="nowrap" css={{ maxHeight: '100vh', flex: '1 1 auto' }}>
      <ProductRuptureHeader isRefetching={isRefetching} />
      {children}
    </Flex>
  );
}
