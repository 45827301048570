import { Flex, FloraButton, FloraTypography } from '@grupoboticario/flora-react';
import { ArrowsCircleIcon, CrossHexagonFillIcon } from '@grupoboticario/flora-react-icons';
import { useErrorMessageStore } from '@/shared/state';

export function ErrorMessage({ handleTryAgain }: { handleTryAgain: () => void }) {
  const { error } = useErrorMessageStore();

  return (
    <Flex
      direction="column"
      gapX="$2"
      css={{
        marginTop: '$4',
        padding: '$3',
        backgroundColor: '$statusErrorBackgroundDefault',
        borderRadius: '$medium',
        borderColor: '$error-standard',
        borderStyle: 'solid',
        borderWidth: '2px',
        boxShadow: '$downClose',
      }}
    >
      <Flex direction="row" gapX="$2" align={'flex-end'} css={{ marginBottom: '$4' }}>
        <CrossHexagonFillIcon color="$error-standard" css={{ alignSelf: 'flex-start' }} />
        <FloraTypography
          as="span"
          fontSize="bodySmallStandard"
          css={{ color: '$error-standard', fontWeight: '$medium', fontSize: '$bodyLarge' }}
        >
          {error.message}
        </FloraTypography>
      </Flex>
      <FloraButton
        size="small"
        hierarchy="primary"
        styleSemantic="neutral"
        has="iconRight"
        icon={<ArrowsCircleIcon />}
        isFull
        onClick={handleTryAgain}
        css={{
          backgroundColor: '$error-standard',
          '&:hover': {
            backgroundColor: '$error-standard',
            opacity: 0.8,
          },
        }}
      >
        Tentar novamente
      </FloraButton>
    </Flex>
  );
}
