import { ToogleButton } from '@/shared/components/ToogleButton/ToogleButton';
import { useFilters } from '@/shared/hooks';

export const UnreadButton = () => {
  const { unreadFilter, updateUnreadFilter, isLoadingChats } = useFilters();

  return (
    <ToogleButton
      label="Não lidas"
      altLabel="Filtrar por não lidas"
      isLoading={isLoadingChats}
      isSelected={unreadFilter}
      onSelect={updateUnreadFilter}
    />
  );
};
