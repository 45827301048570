import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { RupturedProductRecommendedTitle } from './ruptured-product-recommended-title';

export function RupturedProductRecommendedNotFound() {
  return (
    <>
      <RupturedProductRecommendedTitle />
      <Flex
        align="center"
        justify="center"
        css={{ padding: '$4', backgroundColor: '$backgroundSecondary', borderRadius: '$medium' }}
      >
        <FloraTypography as="p" fontSize="exceptionsRestricted" css={{ margin: '0', color: '$nonInteractiveAuxiliar' }}>
          Não encontramos produtos recomendados ou os produtos recomendados estão sem estoque.
        </FloraTypography>
      </Flex>
    </>
  );
}
