import { Flex, Card, FloraTypography } from '@grupoboticario/flora-react';
import { LockIcon } from '@grupoboticario/flora-react-icons';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

export function UnsubscribedForm() {
  return (
    <Flex justify="center">
      <Card variant="active" css={{ margin: '1rem 0', pointerEvents: 'none' }}>
        <Flex direction="row" justify="center" align="center">
          <LockIcon color="$9" />
          <FloraTypography
            as="span"
            fontSize="bodySmallStandard"
            css={{ margin: '0 1rem 0 .5rem', lineHeight: '1.2em' }}
          >
            Este contato solicitou não receber mensagens.
          </FloraTypography>
        </Flex>
      </Card>
    </Flex>
  );
}
