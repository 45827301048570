import { CopilotWrapper } from './copilot-wrapper';
import { ProductRupture } from './product-rupture';

export function Copilot() {
  return (
    <CopilotWrapper>
      <ProductRupture />
    </CopilotWrapper>
  );
}
