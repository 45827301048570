import { Flex, Box, FloraTypography, Tag, Image } from '@grupoboticario/flora-react';
import { Image1Icon } from '@grupoboticario/flora-react-icons';
import { Product } from './types';
import { isAvailableProduct } from './isAvailableProduct';

export function ProductDescription({ product, isSimilar }: { product: Product; isSimilar?: boolean }) {
  return (
    <>
      {isSimilar && (
        <Box css={{ flex: '0 1 auto', minWidth: '40px', marginRight: '$4' }}>
          {product?.images?.thumbnail ? (
            <a href={`${product.images.extraLarge}`} target="_blank" rel="noreferrer">
              <Image size="40px" src={product.images.thumbnail} alt={product.name} loading="lazy" />
            </a>
          ) : (
            <Image1Icon size="40px" />
          )}
        </Box>
      )}
      <Flex direction="column" css={{ flex: '1 1 auto', color: '#222' }}>
        <FloraTypography as="p" fontSize="exceptionsRestricted" css={{ margin: '0' }}>
          SKU: {product.sku}
          {product.availability === 'warning' && (
            <Tag
              variant="alert"
              size="small"
              css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
            >
              Quase esgotado
            </Tag>
          )}
          {product.availability === 'available' && (
            <Tag
              variant="success"
              size="small"
              css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
            >
              Disponível
            </Tag>
          )}
          {product.availability === 'unavailable' && (
            <Tag
              variant="disabled"
              size="small"
              css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
            >
              Indisponível
            </Tag>
          )}
          {product.availability === 'critical' && (
            <Tag
              variant="error"
              size="small"
              css={{ display: 'inline-flex', marginLeft: '$1', verticalAlign: 'baseline' }}
            >
              Últimas unidades
            </Tag>
          )}
        </FloraTypography>
        <FloraTypography as="p" fontSize="exceptionsAuxiliar" css={{ margin: '$1 0 0 0' }}>
          {product.name}
        </FloraTypography>
        {(isSimilar || isAvailableProduct(product)) && (
          <FloraTypography
            as="p"
            fontSize="exceptionsAuxiliar"
            css={{ margin: '$1 0 0 0', fontVariant: 'tabular-nums' }}
          >
            R$ {product.price}
          </FloraTypography>
        )}
      </Flex>
    </>
  );
}
