import { deleteCopilot } from '@/shared/services/api/instant-messenger/service/deleteCopilot';
import { queryClient } from '@/shared/services/api/queryClient';
import { useMutation } from '@tanstack/react-query';
import { Copilots } from '../types';

export function useDeleteCopilot() {
  return useMutation({
    mutationKey: ['copilot', 'delete'],
    mutationFn: async ({ chatId, copilotId }: { chatId: string; copilotId: string }) =>
      deleteCopilot({ chatId, copilotId }),
    onMutate(variables) {
      queryClient.setQueryData<Copilots>(['copilot', variables.chatId], copilotInCache => {
        return copilotInCache.filter(copilots => copilots.id !== variables.copilotId);
      });
    },
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries({ queryKey: ['copilot', variables.chatId] });
    },
  });
}
