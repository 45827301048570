import { MessageType } from '@/shared/components/Notifications';

export const GENERIC_ERROR: MessageType = {
  kind: 'error',
  description: 'Desculpe, algo deu errado.',
};

export const GERA_ID_NOT_FOUND: MessageType = {
  kind: 'error',
  description: 'Não foi possível encontrar uma conta associada ao [e-mail/código Gera]. Por favor, tente novamente.',
};

export const CHAT_LIST_FETCH_ERROR: MessageType = {
  kind: 'error',
  description: 'Erro ao consultar suas conversas',
  button: null,
};

export const CHAT_LIST_FILTERED_FETCH_ERROR: MessageType = {
  kind: 'error',
  description: 'Não foi possível aplicar o filtro',
  button: null,
};

export const CHAT_LIST_FILTERED_SUCCESS: MessageType = {
  kind: 'success',
  description: 'Filtro aplicado',
  button: null,
};

export const CHAT_LIST_FILTER_REMOVED_SUCCESS: MessageType = {
  kind: 'success',
  description: 'Filtro removido',
  button: null,
};
