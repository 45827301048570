import { FloraButton, Flex } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import { Product } from './types';
import { ProductDescription } from './product-description';
import { trackInteractionEvent } from '@/shared/services/eventTracker/trackerService';
import { closeRecommendationEvent } from '@/shared/services/eventTracker/events/events';

export function RequestedProduct({
  product,
  confirmDelete,
  setConfirmDelete,
}: {
  product: Product;
  confirmDelete: boolean;
  setConfirmDelete: (value: boolean) => void;
}) {
  function handleCloseRecomendation() {
    setConfirmDelete(true);
    trackInteractionEvent(closeRecommendationEvent);
  }

  return (
    <Flex direction="row" align="center" wrap="nowrap">
      <ProductDescription product={product} />
      {!confirmDelete && (
        <FloraButton
          has="iconOnly"
          icon={<CrossIcon />}
          hierarchy="tertiary"
          styleSemantic="neutral"
          size="small"
          title="Fechar"
          onClick={() => handleCloseRecomendation}
        />
      )}
    </Flex>
  );
}
