import { Copilots } from './types';
import { RupturedProductRecommendedTitle } from './ruptured-product-recommended-title';
import { RupturedProductRecommendedNotFound } from './ruptured-product-recommended-not-found';
import { RupturedProductRecommendedDescription } from './ruptured-product-recommended-description';
import { SimilarProductRecommendedMessage } from './similar-product-recommended-message';

export function RupturedSimilarProduct({
  similarProduct,
  ruptureRecommendedMessage,
  recommendedMessageSent,
  ruptureRecommendedMessageFeedback,
  sendFeedback,
  chatId,
  ruptureId,
  sendNow,
  recommendedMessageIsPending,
}: {
  similarProduct: Copilots[number]['ruptura']['similar'] | null;
  ruptureRecommendedMessage: string;
  recommendedMessageSent: boolean;
  ruptureRecommendedMessageFeedback: number;
  sendFeedback: ({ feedback, chatId, copilotId }: { feedback: number; chatId: string; copilotId: string }) => void;
  chatId: string;
  ruptureId: string;
  sendNow: () => void;
  recommendedMessageIsPending: boolean;
}) {
  const similarProductAvailable =
    similarProduct?.availability !== 'critical' && similarProduct?.availability !== 'unavailable';

  if (similarProduct === null) {
    return <RupturedProductRecommendedNotFound />;
  }

  if (similarProductAvailable) {
    return (
      <>
        <RupturedProductRecommendedTitle />
        <RupturedProductRecommendedDescription similarProduct={similarProduct} />
        <SimilarProductRecommendedMessage
          ruptureRecommendedMessage={ruptureRecommendedMessage}
          recommendedMessageSent={recommendedMessageSent}
          ruptureRecommendedMessageFeedback={ruptureRecommendedMessageFeedback}
          sendFeedback={sendFeedback}
          chatId={chatId}
          ruptureId={ruptureId}
          sendNow={sendNow}
          recommendedMessageIsPending={recommendedMessageIsPending}
        />
      </>
    );
  }
}
