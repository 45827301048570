import { Box, Flex, FloraButton, FloraTypography, keyframes } from '@grupoboticario/flora-react';
import {
  PaperAirplaneIcon,
  ShineIcon,
  ThumbDownOutlineIcon,
  ThumbUpOutlineIcon,
} from '@grupoboticario/flora-react-icons';
import DOMPurify from 'dompurify';
import { SimilarProductCopyMessage } from './similar-product-copy-message';
import { useState } from 'react';
import { queryClient } from '@/shared/services/api/queryClient';
import { useDeleteCopilot } from '../hooks/useDeleteCopilot';
import { useQuery } from '@tanstack/react-query';
import { authService } from '@vdi/auth-service';
import { Copilots } from '../types';
import { formatText } from '@/shared/functions';
import { trackInteractionEvent } from '@/shared/services/eventTracker/trackerService';
import { htmlToMarkdown } from './ruptured-product-item';
import { copyMessageError, emptyError, generateMessageError, sendMessageError } from './errorMessage';
import {
  clickDontLikeEvent,
  clickToLikeEvent,
  generateMessageEvent,
  generateNewMessageEvent,
  sendMessageEvent,
  clickTryToCopyMessageEvent,
  clickTryToGenerateMessageEvent,
  clickTryToSendMessageEvent,
} from '@/shared/services/eventTracker/events/events';
import { ErrorMessage } from './error-message';
import { useErrorMessageStore } from '@/shared/state';

const loadingSkeleton = keyframes({
  to: {
    backgroundPositionX: '-20%',
  },
});

const skeletonAnimation = {
  backgroundColor: 'rgba(0,0,0,.05)',
  background:
    'linear-gradient(100deg,rgba(255, 255, 255, 0.05) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0.05) 60%) rgba(0,0,0,.05)',
  backgroundSize: '200% 100%',
  backgroundPositionX: '180%',
  animation: `1s ${loadingSkeleton} ease-in infinite`,
};

export function SimilarProductRecommendedMessage({
  ruptureRecommendedMessage,
  recommendedMessageSent,
  ruptureRecommendedMessageFeedback,
  sendFeedback,
  chatId,
  copilotId,
  parentCopilotId,
  sendNow,
  recommendedMessageIsPending,
}: {
  ruptureRecommendedMessage: string;
  recommendedMessageSent: boolean;
  ruptureRecommendedMessageFeedback: number;
  sendFeedback: ({ feedback, chatId, copilotId }: { feedback: number; chatId: string; copilotId: string }) => void;
  chatId: string;
  copilotId: string;
  parentCopilotId: string;
  sendNow: () => void;
  recommendedMessageIsPending: boolean;
}) {
  const { mutate: deleteRecommendation } = useDeleteCopilot();
  const [generateNewMessage, setGenerateNewMessage] = useState(false);
  const { error, setError } = useErrorMessageStore();

  const { isLoading } = useQuery({
    enabled: !!generateNewMessage,
    queryKey: ['copilot', chatId, 'newMessage', copilotId],
    queryFn: async ({ signal }): Promise<Copilots> => {
      const { token } = await authService.getTokenOrRedirect();
      try {
        const res = await fetch(`${process.env.API_URL}/v1/chats/${chatId}/copilots/${copilotId}/message`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = await res.json();
        queryClient.setQueryData<Copilots>(['copilot', chatId], copilotsInCache => {
          return copilotsInCache.map(copilot => {
            return {
              ...copilot,
              message: copilot.parentId === data.parentId ? data.message : copilot.message,
            };
          });
        });
        return data;
      } catch (error) {
        console.error('Error fetching copilots:', error);
        setError(generateMessageError);
        throw error;
      }
    },
  });

  const [requestedRuptureRecommendedMessage, setRequestedRuptureRecommendedMessage] = useState(false);
  const [fakeLoadingRuptureRecommendedMessage, setFakeLoadingRuptureRecommendedMessage] = useState(false);

  function handleGenerateMessage() {
    setFakeLoadingRuptureRecommendedMessage(true);
    trackInteractionEvent(generateMessageEvent);

    setTimeout(
      () => {
        setRequestedRuptureRecommendedMessage(true);
        queryClient.invalidateQueries({ queryKey: ['copilot', chatId] }).then(() => {
          setFakeLoadingRuptureRecommendedMessage(false);
        });
      },
      ruptureRecommendedMessage ? 2000 : 5000,
    );
  }

  function handleGenerateNewMessage() {
    setGenerateNewMessage(true);
    trackInteractionEvent(generateNewMessageEvent);
  }

  function handleSendFeedback({
    feedback,
    chatId,
    copilotId,
  }: {
    feedback: number;
    chatId: string;
    copilotId: string;
  }) {
    sendFeedback({ feedback, chatId, copilotId });

    if (feedback === 1) {
      trackInteractionEvent(clickToLikeEvent);
    }

    if (feedback === -1) {
      trackInteractionEvent(clickDontLikeEvent);
    }
  }
  const handleTryToCopyMessage = () => {
    try {
      setError(emptyError);
      navigator.clipboard.writeText(htmlToMarkdown(ruptureRecommendedMessage));
      trackInteractionEvent(clickTryToCopyMessageEvent);
    } catch (error) {
      setError(copyMessageError);
    }
  };

  const handleTryToGenerateMessage = () => {
    try {
      setError(emptyError);
      setGenerateNewMessage(true);
      trackInteractionEvent(clickTryToGenerateMessageEvent);
    } catch (error) {
      setError(generateMessageError);
    }
  };

  const handleTryToSendMessage = () => {
    try {
      setError(emptyError);
      sendNow();
      trackInteractionEvent(clickTryToSendMessageEvent);
    } catch (error) {
      setError(sendMessageError);
    }
  };

  const handleTryAgain = () => {
    switch (error.type) {
      case 'copyMessageError':
        handleTryToCopyMessage();
        break;
      case 'generateMessageError':
        handleTryToGenerateMessage();
        break;
      case 'sendMessageError':
        handleTryToSendMessage();
        break;
      default:
        break;
    }
  };

  const handleSendNow = () => {
    try {
      sendNow();
      trackInteractionEvent(sendMessageEvent);
    } catch (error) {
      setError(sendMessageError);
    }
  };

  if (!requestedRuptureRecommendedMessage || (requestedRuptureRecommendedMessage && !ruptureRecommendedMessage)) {
    if (fakeLoadingRuptureRecommendedMessage) {
      return (
        <Flex
          direction="column"
          css={{ marginTop: '$4', padding: '$4', backgroundColor: '$backgroundSecondary', borderRadius: '$medium' }}
        >
          <Flex direction="row" align="center" gapX="$2">
            <FloraTypography
              as="span"
              fontSize="exceptionsRestricted"
              fontWeight="regular"
              css={{ color: '$nonInteractiveAuxiliar' }}
            >
              Gerando a mensagem…
            </FloraTypography>
            <Box
              css={{
                width: '32px',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,.05)',
                borderRadius: '$small',
                marginLeft: 'auto',
                ...skeletonAnimation,
              }}
            />
            <Box
              css={{
                ...skeletonAnimation,
                width: '32px',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,.05)',
                borderRadius: '$small',
              }}
            />
          </Flex>
          <Flex direction="column" gapY="$2" css={{ marginTop: '$2' }}>
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '100%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
            <Box
              css={{ ...skeletonAnimation, maxWidth: '80%', minHeight: '10px', backgroundColor: 'rgba(0,0,0,.05)' }}
            />
          </Flex>
          <Flex direction="row" justify="space-between" gapX="$2" css={{ marginTop: '$4' }}>
            <Box
              css={{
                ...skeletonAnimation,
                flex: '1 1 auto',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '$small',
              }}
            />
            <Box
              css={{
                ...skeletonAnimation,
                flex: '1 1 auto',
                height: '32px',
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '$small',
              }}
            />
          </Flex>
        </Flex>
      );
    }
    return (
      <FloraButton
        has="iconRight"
        icon={<ShineIcon />}
        hierarchy="secondary"
        styleSemantic="neutral"
        size="small"
        isFull
        css={{ marginTop: '$4' }}
        onClick={handleGenerateMessage}
        isLoading={fakeLoadingRuptureRecommendedMessage}
        disabled={fakeLoadingRuptureRecommendedMessage}
      >
        Gerar mensagem
      </FloraButton>
    );
  }

  if (
    error.message !== '' ||
    !recommendedMessageSent ||
    (ruptureRecommendedMessage && generateNewMessage && !recommendedMessageSent)
  ) {
    return (
      <>
        {error.type !== 'generateMessageError' && (
          <Flex
            direction="column"
            wrap="wrap"
            css={{
              marginTop: '$4',
              padding: '$3',
              backgroundColor: '$backgroundSecondary',
              borderRadius: '$medium',
              boxShadow: '$downClose',
            }}
          >
            <Flex direction="row" wrap="nowrap" align="center" justify="space-between">
              <FloraTypography
                as="span"
                fontSize="exceptionsRestricted"
                css={{ flex: '1 1 auto', color: '$nonInteractiveAuxiliar' }}
              >
                Recomendação de mensagem
              </FloraTypography>
              <FloraButton
                has="iconOnly"
                size="small"
                hierarchy="secondary"
                styleSemantic={ruptureRecommendedMessageFeedback === 1 ? 'success' : 'neutral'}
                icon={<ThumbUpOutlineIcon />}
                css={{
                  backgroundColor: `${ruptureRecommendedMessageFeedback === 1 && '$statusSuccessNonPrimaryButtonActive'}`,
                  pointerEvents: `${ruptureRecommendedMessageFeedback === 1 ? 'none' : 'auto'}`,
                }}
                onClick={() => handleSendFeedback({ chatId, copilotId, feedback: 1 })}
              />
              <FloraButton
                has="iconOnly"
                size="small"
                hierarchy="secondary"
                styleSemantic={ruptureRecommendedMessageFeedback === -1 ? 'warning' : 'neutral'}
                icon={<ThumbDownOutlineIcon />}
                css={{
                  backgroundColor: `${ruptureRecommendedMessageFeedback === -1 && '$statusErrorNonPrimaryButtonActive'}`,
                  pointerEvents: `${ruptureRecommendedMessageFeedback === -1 ? 'none' : 'auto'}`,
                  marginLeft: '$2',
                }}
                onClick={() => handleSendFeedback({ chatId, copilotId, feedback: -1 })}
              />
            </Flex>
            <FloraTypography
              as="p"
              fontSize="bodySmallStandard"
              css={{ margin: '$2 0 0 0', textWrap: 'pretty', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(formatText(ruptureRecommendedMessage)),
              }}
            />
            {!error.message && (
              <Flex direction="row" wrap="no-wrap" gapX="$2" css={{ marginTop: '$4' }}>
                <FloraButton
                  size="small"
                  hierarchy="primary"
                  styleSemantic="neutral"
                  has="iconRight"
                  icon={<PaperAirplaneIcon />}
                  isFull
                  onClick={handleSendNow}
                  disabled={recommendedMessageIsPending}
                  isLoading={recommendedMessageIsPending}
                >
                  Enviar
                </FloraButton>
                <SimilarProductCopyMessage
                  ruptureRecommendedMessage={ruptureRecommendedMessage}
                  recommendedMessageIsPending={recommendedMessageIsPending}
                />
              </Flex>
            )}
          </Flex>
        )}
        {error.message !== '' && <ErrorMessage handleTryAgain={handleTryAgain} />}
        {!error.message && (
          <FloraButton
            has="iconRight"
            icon={<ShineIcon />}
            hierarchy="tertiary"
            styleSemantic="neutral"
            size="small"
            isFull
            css={{ marginTop: '$4' }}
            onClick={() => handleGenerateNewMessage()}
            disabled={copilotId !== parentCopilotId || isLoading || generateNewMessage}
            isLoading={isLoading}
            title="Só é possível gerar uma nova recomendação de mensagem por ruptura"
          >
            Gerar nova mensagem
          </FloraButton>
        )}
      </>
    );
  }

  return (
    <>
      {error.type !== 'sendMessageError' && (
        <>
          <Flex
            direction="column"
            wrap="wrap"
            css={{
              marginTop: '$4',
              padding: '$3',
              backgroundColor: '$backgroundSecondary',
              borderRadius: '$medium',
              boxShadow: '$downClose',
            }}
          >
            <Flex direction="column">
              <FloraTypography fontSize="bodySmallStandard" fontWeight="medium">
                Mensagem enviada!
              </FloraTypography>
              <Flex direction="row" wrap="nowrap" align="center" justify="space-between">
                <FloraTypography
                  as="span"
                  fontSize="exceptionsRestricted"
                  css={{ flex: '1 1 auto', color: '$nonInteractiveAuxiliar' }}
                >
                  O que achou do texto recomendado?
                </FloraTypography>
                <FloraButton
                  has="iconOnly"
                  size="small"
                  hierarchy="secondary"
                  styleSemantic={ruptureRecommendedMessageFeedback === 1 ? 'success' : 'neutral'}
                  icon={<ThumbUpOutlineIcon />}
                  css={{
                    backgroundColor: `${ruptureRecommendedMessageFeedback === 1 && '$statusSuccessNonPrimaryButtonActive'}`,
                    pointerEvents: `${ruptureRecommendedMessageFeedback === 1 ? 'none' : 'auto'}`,
                  }}
                  onClick={() => {
                    sendFeedback({ chatId, copilotId, feedback: 1 });
                    if (recommendedMessageSent) {
                      setTimeout(() => {
                        deleteRecommendation({ chatId, copilotId });
                      }, 5000);
                    }
                  }}
                />
                <FloraButton
                  has="iconOnly"
                  size="small"
                  hierarchy="secondary"
                  styleSemantic={ruptureRecommendedMessageFeedback === -1 ? 'warning' : 'neutral'}
                  icon={<ThumbDownOutlineIcon />}
                  css={{
                    backgroundColor: `${ruptureRecommendedMessageFeedback === -1 && '$statusErrorNonPrimaryButtonActive'}`,
                    pointerEvents: `${ruptureRecommendedMessageFeedback === -1 ? 'none' : 'auto'}`,
                    marginLeft: '$2',
                  }}
                  onClick={() => {
                    sendFeedback({ chatId, copilotId, feedback: -1 });
                    if (recommendedMessageSent) {
                      setTimeout(() => {
                        deleteRecommendation({ chatId, copilotId });
                      }, 5000);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          {recommendedMessageSent && ruptureRecommendedMessageFeedback !== 0 && (
            <FloraTypography
              fontSize="bodySmallStandard"
              fontWeight="regular"
              css={{ textAlign: 'center', margin: '$4 0 0 0' }}
            >
              Removendo em 5 segundos…
            </FloraTypography>
          )}
        </>
      )}
    </>
  );
}
