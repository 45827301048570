import { EventType, events } from '@/shared/services/events';
import { useNotificationListener } from '@vdi/navbar';

enum MessageEvent {
  RECEIVED_MESSAGE = 'chat.message',
  RECEIVED_SERVICE_REQUEST = 'chat.service-request',
  RECEIVED_MESSAGE_REACTION = 'chat.reaction',
  RECEIVED_MESSAGE_STATUS = 'chat.status',
  CONNECT_ERROR = 'connect_error',
  RECEIVED_COPILOT = 'chat.copilot',
}

/**
 * Encaminha o evento recebido via api de notificação para o event emitter interno
 * @param eventName Nome do evento
 * @returns Função para assinatura do evento
 */
function forwardEvent(eventName: keyof EventType) {
  return ({ data }: { data: EventType[typeof eventName] }) => {
    events.emit(eventName, data);
  };
}

export function useNotifications() {
  useNotificationListener(MessageEvent.RECEIVED_MESSAGE, forwardEvent('MESSAGE_RECEIVED'));
  useNotificationListener(MessageEvent.RECEIVED_MESSAGE_STATUS, forwardEvent('MESSAGE_STATUS_RECEIVED'));
  useNotificationListener(MessageEvent.RECEIVED_MESSAGE_REACTION, forwardEvent('MESSAGE_REACTION_RECEIVED'));
  useNotificationListener(MessageEvent.RECEIVED_SERVICE_REQUEST, forwardEvent('SERVICE_REQUEST_RECEIVED'));
  useNotificationListener(MessageEvent.RECEIVED_COPILOT, forwardEvent('COPILOT_RECEIVED'));
}
