import { Flex } from '@grupoboticario/flora-react';
import { useState } from 'react';
import { Copilots } from '../types';
import { Product } from './types';
import { RequestedProduct } from './requested-product';
import { ConfirmDeletion } from '../confirm-deletion';

export function ProductItem({
  chatId,
  deleteCopilot,
  product,
  copilotId,
}: {
  chatId: string;
  deleteCopilot: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
  product: Product;
  copilotId: Copilots[number]['id'];
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <Flex
      direction="column"
      css={{ backgroundColor: '$backgroundPrimary', margin: '$2', padding: '$4', borderRadius: '$medium' }}
    >
      {confirmDelete ? (
        <ConfirmDeletion
          deleteCopilot={deleteCopilot}
          chatId={chatId}
          copilotId={copilotId}
          setConfirmDelete={setConfirmDelete}
        />
      ) : (
        <RequestedProduct product={product} confirmDelete={confirmDelete} setConfirmDelete={setConfirmDelete} />
      )}
    </Flex>
  );
}
