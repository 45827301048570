import { useQuery } from '@tanstack/react-query';
import { isSubscribed } from '@/shared/services/api/instant-messenger/service';
import { Account, Reseller } from '@/shared/types/interfaces';

export function useFetchIsSubscribed({
  accountId,
  contactId,
  enabled,
}: {
  accountId: Account['id'];
  contactId: Reseller['id'];
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [accountId, contactId, 'isSubscribed'],
    queryFn: () => isSubscribed({ accountId, contactId }),
    enabled,
    select(data) {
      return data;
    },
  });
}
