import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import { ProductRuptureMain } from './main';
import { ProductRuptureWrapper } from './wrapper';

export function ErrorState() {
  return (
    <ProductRuptureWrapper>
      <ProductRuptureMain align="center" justify="center" css={{ padding: '0 $8', textAlign: 'center' }}>
        <Flex
          align="center"
          justify="center"
          css={{ width: '88px', height: '88px', backgroundColor: '$backgroundSecondary', borderRadius: '100%' }}
        >
          <CrossCircleIcon size="32px" />
        </Flex>
        <FloraTypography as="h3" fontSize="bodyLargeShort" css={{ margin: '$4 0 0 0' }}>
          Erro ao exibir as informações
        </FloraTypography>
        <FloraTypography
          as="p"
          fontSize="bodySmallStandard"
          css={{ margin: '$2 0 0 0', color: '$nonInteractiveAuxiliar' }}
        >
          Não foi possível carregar as
          <br /> informações.
        </FloraTypography>
      </ProductRuptureMain>
    </ProductRuptureWrapper>
  );
}
