import { Copilots } from '../types';
import { RupturedProductItem } from './ruptured-product-item';
import { ProductItem } from './product-item';
import { FloraTypography } from '@grupoboticario/flora-react';
import { isAvailableProduct } from './isAvailableProduct';

export function ProductList({
  copilots,
  chatId,
  deleteCopilot,
}: {
  copilots: Copilots;
  chatId: string;
  deleteCopilot: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
}) {
  return (
    <>
      {copilots.map(c => {
        if (!isAvailableProduct(c.ruptura.product)) {
          return (
            <RupturedProductItem
              chatId={chatId}
              key={c.id}
              deleteCopilot={deleteCopilot}
              rupture={c.ruptura}
              copilotId={c.id}
              parentCopilotId={c.parentId}
              ruptureRecommendedMessage={c.message}
              ruptureRecommendedMessageFeedback={c.feedback}
            />
          );
        }
      })}
      <FloraTypography
        fontSize="bodyLargeShort"
        as="p"
        css={{
          position: 'relative',
          margin: '$4 0',
          color: '$nonInteractiveAuxiliar',
          textAlign: 'center',
          textTransform: 'uppercase',
          '&::before': {
            content: '',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '1px',
            borderTop: '1px solid $nonInteractiveAltAuxiliar',
            top: 'calc(50%)',
            left: 0,
            zIndex: 0,
          },
        }}
      >
        <FloraTypography
          as="span"
          fontSize="bodySmallShort"
          css={{
            position: 'relative',
            backgroundColor: '$backgroundSecondary',
            verticalAlign: 'medium',
            padding: '$0 $4',
            zIndex: 1,
            fontWeight: '$bold',
            letterSpacing: '$wider',
          }}
        >
          DISPONÍVEL
        </FloraTypography>
      </FloraTypography>
      {copilots.map(c => {
        if (isAvailableProduct(c.ruptura.product)) {
          return (
            <ProductItem
              chatId={chatId}
              key={c.id}
              deleteCopilot={deleteCopilot}
              product={c.ruptura.product}
              copilotId={c.id}
            />
          );
        }
      })}
    </>
  );
}
