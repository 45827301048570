import { useFilters } from '@/shared/hooks';
import { Box } from '@grupoboticario/flora-react';

export const ResultCount = () => {
  const { isLoadingChats, chatListResultData, isFilterEmpty } = useFilters();

  const onLoadingChats = isFilterEmpty() ? 'Carregando conversas...' : 'Aplicando filtros...';

  const resultsCountText = () => {
    const { totalCount, filteredCount } = chatListResultData;
    let text;

    if (totalCount == 0) {
      text = 'Nenhuma conversa';
    } else if (isFilterEmpty()) {
      //Descritivo para quando não há filtro por tags e status
      text = `${totalCount} conversas`;
    } else {
      //Descritivo para quando há um dos filtros aplicados
      text = `${filteredCount} de ${totalCount} conversas`;
    }

    return totalCount == 1 ? text.replace('conversas', 'conversa') : text;
  };

  return (
    <Box
      css={{
        padding: '$1 $2',
        backgroundColor: '$backgroundSecondary',
        borderRadius: '$small',
        color: '$nonInteractiveAuxiliar',
        fontSize: '$auxiliar',
      }}
    >
      {isLoadingChats || !chatListResultData ? onLoadingChats : resultsCountText()}
    </Box>
  );
};
